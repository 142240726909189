export default (self) => {
  return [
    {
      value: "data-table-expand",
      text: "",
      sortable: false,
      visible: true,
      copyable: false,
    },
    {
      value: "lastOnlineAt",
      text: "Last Online At",
      togglable: true,
      visible: true,
      width: 105,
    },
    {
      value: "status",
      text: "Status",
      togglable: true,
      visible: true,
      width: 145,
      align: "center",
      toStringFn: (item, key) => self.getStatusText(item[key]),
    },
    {
      value: "exid",
      text: "ID",
      togglable: true,
      visible: false,
      width: 100,
    },
    {
      value: "name",
      text: "Name",
      togglable: true,
      visible: true,
      width: 200,
    },
    {
      value: "publicNote",
      text: "Public Notes",
      width: 300,
      sortable: false,
      visible: true,
    },
    {
      value: "privateNote",
      text: "Private Notes",
      width: 300,
      sortable: false,
      visible: true,
    },
    {
      value: "timezone",
      text: "Timezone",
      togglable: true,
      visible: true,
    },
    {
      value: "zohoId",
      text: "Zoho",
      sortable: false,
      filterable: false,
      width: 40,
      align: "center",
      toStringFn: (item, key) =>
        item[key] &&
        `${self.$config.public.zohoCrmUrl}/tab/CustomModule4/${item[key]}`,
    },
    {
      value: "simNumber",
      text: "SIM",
      togglable: true,
      visible: true,
      width: 140,
    },
    {
      value: "voltage",
      text: "Voltage",
      visible: self.report === "offline-cameras",
      width: 140,
      sortable: false,
    },
    {
      value: "powerType",
      text: "Power Type",
      width: 100,
      sortable: true,
      visible: true,
    },
    {
      value: "powerSchedule",
      text: "Power Schedule",
      width: 115,
      sortable: true,
      visible: true,
    },
    {
      value: "cameraHost",
      text: "Camera IP",
      sortable: false,
      togglable: true,
      visible: true,
      width: 165,
    },
    {
      value: "nvrHttpPort",
      text: "NVR Port",
      sortable: false,
      togglable: true,
      visible: false,
      width: 80,
      align: "center",
    },
    {
      value: "nvrRtspPort",
      text: "RTSP Port",
      sortable: false,
      togglable: true,
      visible: false,
      width: 80,
      align: "center",
    },
    {
      value: "cameraUsername",
      text: "Username",
      togglable: true,
      visible: false,
      width: 100,
    },
    {
      value: "cameraPassword",
      text: "Password",
      sortable: false,
      togglable: true,
      visible: false,
    },
    {
      value: "lastPolledAt",
      text: "Last Polled At",
      togglable: true,
      visible: false,
      width: 150,
    },
    {
      value: "createdAt",
      text: "Created At",
      sortable: false,
      togglable: true,
      visible: false,
      width: 150,
    },
  ]
}

import { PowerSchedules, PowerTypes } from "@/components/constants.js"

import { CameraStatus } from "@evercam/shared/types/camera"

const statuses = [
  { name: "All", value: "" },
  { name: "Under Maintenance", value: CameraStatus.UnderMaintenance },
  { name: "Offline", value: CameraStatus.Offline },
]

export default (self) => {
  let includeStatusFilter = {}
  if (self.report === "offline-cameras") {
    includeStatusFilter = {
      status: {
        component: "SelectSearchFilter",
        attributes: { items: statuses },
      },
    }
  }

  return {
    timezone: {
      component: "TextFieldSearchFilter",
    },
    ...includeStatusFilter,
    powerType: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...PowerTypes],
      },
    },
    powerSchedule: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...PowerSchedules],
      },
    },
    name: {
      component: "TextFieldSearchFilter",
      name: "cameraName",
    },
    publicNote: {
      component: "TextFieldSearchFilter",
      name: "publicNote",
      offline: true,
    },
    privateNote: {
      component: "TextFieldSearchFilter",
      name: "privateNote",
      offline: true,
    },
  }
}
